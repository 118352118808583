import { cn } from 'utils/shadcn';
import { cva, type VariantProps } from 'class-variance-authority';
import React from 'react';

const typographyAffects = {
  default: '',
  lead: 'text-xl text-muted-foreground',
  '4xl': 'text-4xl font-semibold',
  '3xl': 'text-3xl font-semibold',
  '2xl': 'text-2xl font-semibold',
  xl: 'text-xl font-semibold',
  large: 'text-lg font-semibold',
  small: 'text-sm font-medium leading-none',
  xs: 'text-xs font-normal leading-none',
  muted: 'text-sm text-muted-foreground',
};

export type TypographyAffects = keyof typeof typographyAffects;

export const typographyVariants = cva('text-sm', {
  variants: {
    variant: {
      h1: 'scroll-m-20 font-gotham text-3xl uppercase font-black tracking-wide leading-[0.9]',
      h2: 'scroll-m-20 font-gotham text-xl font-black tracking-tight',
      p: '',
      span: '',
    },
    affects: typographyAffects,
  },
  defaultVariants: {
    variant: 'h1',
    affects: 'default',
  },
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    VariantProps<typeof typographyVariants> {}

const Typography = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ className, variant, affects, ...props }, ref) => {
    const Comp = variant ?? 'p';
    return (
      <Comp
        className={cn(typographyVariants({ variant, affects, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Typography.displayName = 'H1';

export default Typography;
