import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';

import { Button } from 'components/ui/button';
import DiscordIcon from 'icons/discord';
import Link from 'next/link';
import { Separator } from 'components/ui/separator';
import Typography from 'components/ui/typography';

export default function ErrorCard({
  error,
  title,
  description,
  actions,
}: {
  error?: string;
  title?: string;
  description?: string;
  actions?: React.ReactNode;
}) {
  return (
    <div className="flex min-h-screen-minus-header animate-fade-up flex-col items-center justify-center gap-8">
      <div className="bg-frost flex max-w-prose flex-col justify-center gap-2 rounded-lg p-4 pt-8">
        <Typography variant="h2" className="text-start">
          {title ?? 'Oof, this is embarrassing. 🙈'}
        </Typography>

        <Typography variant="p">
          {description ??
            "The developer didn't gracefully handle an error on the page so now you're seeing this. Give 'er a page refresh, see if that helps."}
        </Typography>

        {error && (
          <Accordion type="single" collapsible>
            <AccordionItem value="item-1" className="border-none">
              <AccordionTrigger className="text-sm text-muted-foreground">
                Click to view error details
              </AccordionTrigger>
              <AccordionContent>
                <Typography
                  variant="p"
                  className="rounded-lg bg-destructive p-4"
                >
                  {error}
                </Typography>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}

        <Separator />

        <div className="grid grid-cols-[1fr_auto] items-center gap-4">
          <Typography variant="p" className="text-start">
            If you continue to see this, please reach out to support at{' '}
            <Link
              href="mailto:support@pullbox.gg"
              className="text-gold underline"
            >
              support@pullbox.gg
            </Link>{' '}
            or reach out to us on our Discord.
          </Typography>
          <Button
            style={{ background: '#5865F2' }}
            size="icon"
            asChild
            className="mt-auto text-foreground"
          >
            <Link href="https://discord.gg/UZ6NndQfua" target="_blank">
              <DiscordIcon className="my-auto" />
            </Link>
          </Button>
        </div>
      </div>

      {actions}
    </div>
  );
}
