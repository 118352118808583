import React from 'react';

interface DiscordIconProps {
  className?: string;
}

const DiscordIcon: React.FC<DiscordIconProps> = ({ className }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.3025 3.38824C12.3322 2.93595 11.2817 2.60767 10.1875 2.418C10.1779 2.4177 10.1684 2.4195 10.1595 2.42328C10.1507 2.42706 10.1428 2.43273 10.1364 2.43989C10.0051 2.68062 9.85193 2.99431 9.7498 3.23504C8.58916 3.05996 7.40883 3.05996 6.24819 3.23504C6.14606 2.98701 5.99287 2.68062 5.85426 2.43989C5.84697 2.4253 5.82508 2.418 5.8032 2.418C4.70895 2.60767 3.66576 2.93595 2.68823 3.38824C2.68093 3.38824 2.67364 3.39553 2.66634 3.40283C0.682102 6.3719 0.134977 9.26072 0.404892 12.1204C0.404892 12.135 0.412187 12.1495 0.426777 12.1568C1.73988 13.1198 3.00191 13.7034 4.24936 14.09C4.27125 14.0973 4.29313 14.09 4.30043 14.0754C4.59223 13.6742 4.85485 13.2511 5.08099 12.8061C5.09558 12.7769 5.08099 12.7477 5.05181 12.7404C4.636 12.5799 4.24207 12.3903 3.85543 12.1714C3.82625 12.1568 3.82625 12.1131 3.84814 12.0912C3.92838 12.0328 4.00863 11.9672 4.08887 11.9088C4.10346 11.8942 4.12535 11.8942 4.13994 11.9015C6.64942 13.0468 9.35587 13.0468 11.8362 11.9015C11.8508 11.8942 11.8726 11.8942 11.8872 11.9088C11.9675 11.9745 12.0477 12.0328 12.128 12.0985C12.1571 12.1204 12.1571 12.1641 12.1207 12.1787C11.7413 12.4049 11.3401 12.5872 10.9243 12.7477C10.8951 12.755 10.8878 12.7915 10.8951 12.8134C11.1286 13.2584 11.3912 13.6815 11.6757 14.0827C11.6976 14.09 11.7194 14.0973 11.7413 14.09C12.9961 13.7034 14.2581 13.1198 15.5712 12.1568C15.5858 12.1495 15.5931 12.135 15.5931 12.1204C15.9141 8.81572 15.0606 5.94879 13.3316 3.40283C13.3243 3.39553 13.3171 3.38824 13.3025 3.38824ZM5.46033 10.3769C4.70895 10.3769 4.08158 9.68383 4.08158 8.83031C4.08158 7.9768 4.69436 7.28377 5.46033 7.28377C6.2336 7.28377 6.84638 7.98409 6.83909 8.83031C6.83909 9.68383 6.22631 10.3769 5.46033 10.3769ZM10.545 10.3769C9.79357 10.3769 9.1662 9.68383 9.1662 8.83031C9.1662 7.9768 9.77898 7.28377 10.545 7.28377C11.3182 7.28377 11.931 7.98409 11.9237 8.83031C11.9237 9.68383 11.3182 10.3769 10.545 10.3769Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DiscordIcon;
