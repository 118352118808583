// app/error.tsx
'use client'; // Error components must be Client Components

import {
  appRouterSsrErrorHandler,
  type AppRouterErrorProps,
} from '@highlight-run/next/ssr';
import ErrorCard from 'components/error-card';
import { Button } from 'components/ui/button';

export default appRouterSsrErrorHandler(
  ({ error, reset }: AppRouterErrorProps) => {
    console.error(error);

    return (
      <ErrorCard
        error={error.message}
        actions={
          <Button
            onClick={
              () => reset() // Attempt to recover by trying to re-render the segment
            }
            className="mx-auto w-fit"
            variant="frost"
            size="xl"
          >
            Try again
          </Button>
        }
      />
    );
  },
);
